<template>
  <transition name="appear">
    <Go v-if="visible" :to="to" class="go">
      <Arrow class="down" />
    </Go>
  </transition>
</template>

<script>
import Arrow from '@/assets/images/chevron-down.svg';

export default {
  components: { Arrow },
  props: { to: { type: String, default: null } },
  data: () => ({ visible: true }),
  mounted() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.visible = window.pageYOffset < 5;
    },
  },
};
</script>

<style scoped lang="scss">
.down {
  position: fixed;
  z-index: 10;
  bottom: 2rem;
  left: calc(50% - px(20));
  width: px(40);
  transition: 0.3s ease;

  @include hover {
    color: black;
    cursor: pointer;
  }

  @media (max-width: theme("screens.md")) {
    //top: calc(100vh - px(23));
   bottom: px(23);
  }
}
</style>
