<template>
  <section class="haus-container relative py-28 text-salmon">
    <div class="the-green" />

    <div v-view class="row-10">
      <div class="col-0 md:col-2 md:offset-1 backdrop">
        <Image :src="data.bildlinks" />
      </div>
      <div class="col-5 md:col-2 focus relative bg-gray">
        <h3>Architektur</h3>
        <div class="aspect cover">
          <Image :src="data.portraitlinks" />
        </div>
      </div>
      <div class="col-5 md:col-2 focus relative bg-gray">
        <h3>Innen</h3>
        <div class="aspect cover">
          <Image :src="data.portraitrechts" />
        </div>
      </div>
      <div class="col-0 md:col-2 backdrop">
        <Image :src="data.bildrechts" />
      </div>
    </div>

    <div class="row-10 extra">
      <div class="col-5 bg-green text-right p-4 md:p-12">
        <h3>Niklaus Lohri</h3>
        <p>dipl. Arch. SIA/HBK</p>
      </div>
      <div class="col-5 p-4 md:p-12 text-green">
        <h3>Victoria «Vicky» Lohri</h3>
        <p>dipl. Innenarchitektin HF</p>
        <p>dipl. Immobilienbewerterin FH</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: { data: Object },
};
</script>

<style lang="scss" scoped>
.the-green {
  @apply bg-green;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
}

.focus {
  h3 {
    position: absolute;
    z-index: 1;
    font-family: "Quentell";
    font-size: 2rem;
    @screen md {
      font-size: 3rem;
    }
  }

  &:nth-of-type(2) h3 {
    bottom: 20%;
    right: 0;
  }
  &:nth-of-type(3) h3 {
    bottom: 35%;
  }

  &:hover .aspect {
    opacity: 1;
  }
  @media (max-width: theme("screens.md")) {
    .view-in--full & .aspect {
      opacity: 1;
    }
    &:nth-of-type(1) .aspect {
      transition-delay: 0.1s;
    }
    &:nth-of-type(2) .aspect {
      transition-delay: 0.2s;
    }
  }
  .aspect {
    opacity: 0;
    transition: 0.4s ease;
  }
  .aspect::before {
    padding-top: math.div(100%, 3) * 3.5;
  }
}

.extra {
  h3 {
    font-size: 1.3rem;
    margin-bottom: 0.7rem;
    @screen md {
      font-size: 1.5rem;
    }
  }
  p {
    font-size: 0.9rem;
    @screen md {
      font-size: 1.1rem;
    }
  }
}

.backdrop {
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
