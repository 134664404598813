<template>
  <div>
    <div class="intro-container text-green" @click="scrollDown">
      <div class="row-10 justify-center">
        <div class="col-8 md:col-4">
          <div class="intro-box inset">
            <h1 class="page-title text-center" v-text="payload.seitentitel" />
            <div class="intro-text-box text-center" v-html="payload.introtext" />
            <div class="intro-signature text-center" v-html="payload.signature" />
          </div>
          <Down />
        </div>
      </div>
    </div>
    <LohriHaus :data="payload" />
  </div>
</template>

<script>
import Down from '@/components/utils/Down.vue';
import LohriHaus from '@/components/parts/LohriHaus.vue';

export default {
  components: {
    LohriHaus,
    Down,
  },
  props: {
    payload: Object,
  },
  methods: {
    scrollDown() {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-container {
  position: relative;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.arrow-down {
  position: absolute;
  bottom: 10%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translateX(-50%);
  cursor: pointer;
}

.intro-box {
  display: flex;
  flex-direction: column;
  align-items: center ;
}

.page-title {
  margin-bottom: 1rem;
  @screen md {
    margin-bottom: 4rem;
  }
}

.intro-text-box {
  margin-bottom: 2rem;
}

.intro-signature {
  :deep(p) {
    font-size: px(16);
  }
}

</style>
